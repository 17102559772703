var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":"Add / Edit Amenity","items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-form',{ref:"forum",attrs:{"enctype":"multipart/form-data"}},[_c('label',{staticClass:"col-12 mb-2 pl-0",attrs:{"for":"input-1"}},[_vm._v(" Amenity "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_vm._l((_vm.form.amenity),function(val,index){return _c('div',{key:index,staticClass:"row mb-3"},[_c('div',{staticClass:"col-8"},[_c('b-form-group',{staticClass:"mb-0",attrs:{"id":"input-group-1"}},[_c('b-form-input',{key:val.id,class:{
                      'is-invalid':
                        _vm.submitted &&
                        !_vm.$v.form.amenity.$each[index].name.$pending &&
                        !_vm.$v.form.amenity.$each[index].name.$model,
                    },attrs:{"id":"input-1","placeholder":"Enter Amenity"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;return _vm.preventLeadingSpace.apply(null, arguments)}},model:{value:(val.name),callback:function ($$v) {_vm.$set(val, "name", $$v)},expression:"val.name"}}),(
                      _vm.submitted && !_vm.$v.form.amenity.$each[index].name.$model
                    )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Amenities is required. ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-md-4"},[(_vm.form.amenity.length - 1 == index)?_c('b-button',{staticClass:"mr-3",attrs:{"variant":"success","disabled":_vm.form.amenity[index].name == 0},on:{"click":function($event){$event.preventDefault();return _vm.addOption(index)}}},[_vm._v("Add Amenity")]):_vm._e(),_c('b-button',{attrs:{"disabled":_vm.form.amenity.length <= 1,"variant":"danger"},on:{"click":function($event){$event.preventDefault();return _vm.removeOption(index)}}},[_vm._v("Remove Amenity")])],1)])}),_c('b-button',{attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submitData.apply(null, arguments)}}},[_vm._v("Save Data ")])],2)],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }