<template>
  <Layout>
    <PageHeader title="Add / Edit Amenity" :items="items"> </PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="forum">
              <label for="input-1" class="col-12 mb-2 pl-0">
                Amenity <span style="color: red">*</span>
              </label>
              <div
                class="row mb-3"
                v-for="(val, index) in form.amenity"
                :key="index"
              >
                <div class="col-8">
                  <b-form-group id="input-group-1" class="mb-0">
                    <b-form-input
                      id="input-1"
                      v-model="val.name"
                      :key="val.id"
                      placeholder="Enter Amenity"
                      @keydown.space="preventLeadingSpace"
                      :class="{
                        'is-invalid':
                          submitted &&
                          !$v.form.amenity.$each[index].name.$pending &&
                          !$v.form.amenity.$each[index].name.$model,
                      }"
                    ></b-form-input>
                    <div
                      v-if="
                        submitted && !$v.form.amenity.$each[index].name.$model
                      "
                      class="invalid-feedback"
                    >
                      Amenities is required.
                    </div>
                  </b-form-group>
                </div>
                <div class="col-md-4">
                  <b-button
                    variant="success"
                    class="mr-3"
                    v-if="form.amenity.length - 1 == index"
                    @click.prevent="addOption(index)"
                    :disabled="form.amenity[index].name == 0"
                    >Add Amenity</b-button
                  >
                  <b-button
                    :disabled="form.amenity.length <= 1"
                    variant="danger"
                    @click.prevent="removeOption(index)"
                    >Remove Amenity</b-button
                  >
                </div>
              </div>
              <b-button
                type="submit"
                variant="primary"
                @click.prevent="submitData"
                >Save Data
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "@/mixins/request";
import clinicMixin from "@/mixins/ModuleJs/amenity";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    Layout,
    PageHeader,
  },
  mixins: [MixinRequest, clinicMixin],
  data() {
    return {
      submitted: false,
      items: [
        {
          text: "Back",
          href: `/clinic/edit-clinic/${this.$route.params.id}`,
        },
        {
          text: "Data",
        },
      ],
    };
  },
  validations: {
    form: {
      amenity: {
        $each: {
          name: { required },
        },
      },
    },
  },
  created() {
    this.fetchSingleHospital(this.$route.params.id);
  },
};
</script>
